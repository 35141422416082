
const Username = process.env.REACT_APP_USERNAME;
const Password = process.env.REACT_APP_PASSWORD;
module.exports = {
  //Local baseURL
    // BASE_URL: 'http://localhost:5002',

  // staging baseURL
    // BASE_URL: 'https://ds5p34fp5g.execute-api.us-east-1.amazonaws.com',

  //Production baseURL
    BASE_URL: 'https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com',


  //Models A/B Testing BaseURL
    // BASE_URL_A: "https://52o5pmhwsa.execute-api.us-east-1.amazonaws.com/Prod/ciqler",
    // BASE_URL_A: "https://lwujtpji1g.execute-api.us-east-1.amazonaws.com/Prod/ciqler",
       BASE_URL_A: "https://98upd0g6v9.execute-api.us-east-1.amazonaws.com/Prod/ciqlertesting",
      //  BASE_URL_A: "http://127.0.0.1:3000/ciqlertesting",



    // Shopify_url: 'http://localhost:55289/api/auth/callback?shop=vendorgb.myshopify.com',

    //etc
    Username,
    Password,
  };
  
  