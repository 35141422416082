import { Badge } from "../../ui/badge.jsx";
import { Button } from "../../ui/button.jsx";
import { Calendar, Heart, Share2, Edit2, Check, Trash2 } from "lucide-react";
import { useState } from "react";
import useEventStore from "../../../Store/EventStore.jsx";

// Utility function to handle safe date formatting
const formatEventDate = (date) => {
  try {
    return date
      ? new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "No date available";
  } catch {
    return "Invalid date";
  }
};

// Component to render images with a carousel effect
const ImageCarousel = ({ images, currentIndex, onImageClick }) => (
  <div
    className="relative overflow-hidden rounded-lg mb-2 h-24 cursor-pointer"
    onClick={onImageClick}
  >
    {images.map((image, index) => {
      const offset = (index - currentIndex + images.length) % images.length;
      return (
        <div
          key={index}
          className="absolute top-0 left-0 w-full h-full transition-all duration-300 ease-in-out"
          style={{
            transform: `translateX(${offset * 10}%) translateY(${
              offset * 5
            }%) scale(${1 - offset * 0.05})`,
            zIndex: images.length - offset,
            opacity: offset === 0 ? 1 : 0.7,
          }}
        >
          <img
            src={image}
            alt={`Event image ${index + 1}`}
            className="w-full h-full object-cover rounded-lg shadow-md"
          />
        </div>
      );
    })}
  </div>
);

// Component to render action buttons
const ActionButtons = ({ onEdit, onDelete, isEditing, isLiked, onLike }) => (
  <div className="flex justify-between items-end mt-3">
    <div className="flex gap-1"></div>
    {/* <Button
        onClick={onLike}
        size="icon"
        variant=""
        className={`rounded-full transition-all duration-300 p-1.5 ${
          isLiked
            ? "bg-red-500 text-white"
            : "bg-gray-200 hover:bg-gray-300 text-gray-600"
        }`}
        aria-label={isLiked ? "Unlike event" : "Like event"}
      >
        <Heart className="h-3 w-3" />
      </Button>
      <Button
        size="icon"
        variant=""
        className="rounded-full bg-gray-200 hover:bg-gray-300 text-gray-600 p-1.5"
        aria-label="Share event"
      >
        <Share2 className="h-3 w-3" />
      </Button> */}
    <div className="flex gap-1">
      <Button
        onClick={onEdit}
        size="icon"
        variant=""
        className={`rounded-full bg-gray-200 hover:bg-gray-300 text-gray-800 p-1.5 ${
          isEditing ? "bg-green-600 text-grey-100 hover:bg-green-700  " : ""
        }`}
        aria-label={isEditing ? "Save changes" : "Edit event details"}
      >
        {isEditing ? (
          <Check className="h-3 w-3" />
        ) : (
          <Edit2 className="h-3 w-3" />
        )}
      </Button>
      <Button
        onClick={onDelete}
        size="icon"
        variant=""
        className="rounded-full bg-gray-200 hover:bg-gray-300 text-red-500 p-1.5"
        aria-label="Delete event"
      >
        <Trash2 className="h-3 w-3" />
      </Button>
    </div>
  </div>
);

const EventCard = ({ eventData, onDelete, onRedirect }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(eventData.eventName);
  const [editedDescription, setEditedDescription] = useState(
    eventData.eventDescription
  );
  const [editedDate, setEditedDate] = useState(eventData.eventDate);
  const { handleEdit: handleEditApi } = useEventStore();

  const type = "events";
  const originalName = eventData.eventName;
  const images = [
    "https://www.sorryonmute.com/wp-content/uploads/2023/01/153_premium-luxury-corporate-gifts-1024x658.png",
    // "https://ideas.hallmark.com/wp-content/uploads/2020/11/MeaningfulGifts_MediaCard.jpg",
    // "https://contentsnare.com/wp-content/uploads/2023/06/client-gift-ideas-1024x576.jpg",
  ];

  const nextImage = (e) => {
    e.stopPropagation(); // Prevent redirect on image click
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent redirect on button click
    if (isEditing) {
      // Save the edited data
      eventData.eventName = editedName;
      eventData.eventDescription = editedDescription;
      eventData.eventDate = editedDate;

      const updatedData = {
        eventName: editedName,
        eventDescription: editedDescription,
        eventDate: editedDate,
      };

      handleEditApi(type, originalName, updatedData);
    }

    setIsEditing(!isEditing);
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent redirect on button click
    if (onDelete) onDelete();
  };

  const handleLike = (e) => {
    e.stopPropagation(); // Prevent redirect on button click
    setIsLiked(!isLiked);
  };

  const handleRedirect = () => {
    if (!isEditing && onRedirect)
      onRedirect(type, eventData.giftID, eventData.eventName);
  };

  return (
    <div
      className="bg-white/80 backdrop-blur-md rounded-2xl p-4 shadow-md border-1 max-w-60 w-full cursor-pointer flex flex-col h-full"
      onClick={handleRedirect}
    >
      {/* Container for the main content */}
      <div className="flex-grow">
        <ImageCarousel
          images={images}
          currentIndex={currentImageIndex}
          onImageClick={nextImage}
        />
        <div className="flex flex-wrap justify-between items-start gap-1 mb-2">
          <div className="w-full">
            {isEditing ? (
              <div onClick={(e) => e.stopPropagation()}>
                <input
                  type="text"
                  name="eventName"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  className="text-sm font-semibold mb-1 w-full bg-transparent border-b border-gray-300 focus:outline-none focus:border-blue-500"
                />
                <input
                  type="date"
                  name="eventDate"
                  value={editedDate.substring(0, 10)} // Format date as YYYY-MM-DD
                  onChange={(e) => setEditedDate(e.target.value)}
                  className="w-full bg-transparent border border-gray-300 rounded-md p-1 mt-2 text-xs"
                />
                <textarea
                  name="eventDescription"
                  placeholder="Enter your event Description"
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  className="w-full bg-transparent border border-gray-300 rounded-md p-1 mt-2 text-xs"
                />
              </div>
            ) : (
              <div>
                <h2 className="text-lg font-semibold mb-1 text-gray-800">
                  {eventData.eventName}
                </h2>
                <div className="flex items-center text-gray-600 mb-1 text-xs">
                  <Calendar className="h-3 w-3 mr-1" />
                  <span>{formatEventDate(eventData.eventDate)}</span>
                </div>
              </div>
            )}
            <div className="flex gap-2 w-full flex-wrap mt-2">
              <Badge
                className={`bg-gradient-to-r from-orange-500/80 to-red-500/50 text-white px-2 py-1 rounded-full text-xxs font-semibold ${
                  eventData.featured ? "bg-green-500" : ""
                }`}
              >
                {eventData.featured ? "Featured" : eventData.category}
              </Badge>
              {eventData.giftID && (
                <Badge className="text-xs font-semibold bg-gradient-to-r from-red-500/80 to-neutral-green-500/80 text-white px-2 py-1 rounded-full">
                  {eventData.giftID.length} saved products
                </Badge>
              )}
            </div>
          </div>
        </div>
        <p className="text-gray-700 mb-2 text-xs leading-snug">
          {!isEditing &&
            (eventData.eventDescription
              ? eventData.eventDescription.split(" ").slice(0, 20).join(" ")
              : "No description provided.")}
        </p>
      </div>
      {/* Action buttons are always at the end */}
      <ActionButtons
        onEdit={handleEdit}
        onDelete={handleDelete}
        isEditing={isEditing}
        isLiked={isLiked}
        onLike={handleLike}
        className="mt-auto" // Ensures ActionButtons stay at the bottom of the component
      />
    </div>
  );
};

export default EventCard;
