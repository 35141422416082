import { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import NavBar from "../components/NavBar/NavBar2";
import loadergif from "../Assets/Images/GeoVase.png";
import Partner1 from "../Assets/Videos/PartnerVideo1Extended.mp4";
import Partner2 from "../Assets/Videos/PartnerVideo2.mp4";
import Partner3 from "../Assets/Videos/PartnerVideo3.mp4";
import PartnerCounter from "../Assets/Videos/Counter.mp4";

const productStages = [
  {
    id: 1,
    src: loadergif,
    alt: "Initial product concept",
    description: "Blueprint to Brilliance",
    content:
      "Witness your product's transformation as it enters our innovation Pipeline.",

    type: "image",
  },
  {
    id: 2,
    src: Partner1,
    alt: "Product prototype",
    description: "Detailing for Distinction",
    content:
      "We generate important details like age, gender, and categories, and suitable events to your product, to help your product stand out, making it easier for people to find exactly what they’re looking for.",

    type: "video",
    scale: "scale-125",
  },
  {
    id: 3,
    src: Partner2,
    alt: "Product with basic features",
    description: "Fusing Feelings and Thoughts",
    content:
      "We infuse your product with empathic annotation to connect with a diverse range of users.",
    type: "video",
    scale: "scale-150",
  },
  {
    id: 4,
    src: Partner3,
    alt: "Product with advanced features",
    description: "Engaging in Endless Exploration",
    content:
      "Your product is ready to conquer new markets as it joins our dynamic portfolio.",
    type: "video",
  },
  {
    id: 5,
    src: PartnerCounter,
    alt: "Final product",
    description: "Building Better Business",
    content:
      "Prepare to see your product thrive with increased referrals and sales.",
    type: "video",
    scale: "scale-125",
  },
];

const descriptions = [
  "Witness your product's transformation as it enters our innovation incubator.",
  "Our designers wield their magic to create a visually stunning experience that captivates your audience.",
  "We infuse your product with empathy to connect with a diverse range of users.",
  "Your product is ready to conquer new markets as it joins our dynamic portfolio.",
  "Prepare to see your product thrive with increased referrals and sales.",
];

const ProductEvolutionContent = ({ currentStage }) => (
  <div className="max-w-lg">
    <motion.h2
      className="text-4xl font-bold mb-4"
      key={currentStage}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {productStages[currentStage].description}
    </motion.h2>
    <motion.p
      className="text-xl"
      key={`p-${currentStage}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      {productStages[currentStage].content}
    </motion.p>
  </div>
);

const ProductStageImage = ({ stage, isActive }) => {
  const [scale, setScale] = useState("scale-100");

  useEffect(() => {
    const handleResize = () => {
      setScale(window.innerWidth < 768 ? stage.scale : "scale-100");
    };

    handleResize(); // Set initial scale
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{
        opacity: isActive ? 1 : 0.3,
        x: isActive ? 0 : 50,
      }}
      transition={{ duration: 0.5 }}
    >
      {stage.type === "image" ? (
        <img src={stage.src} alt={stage.alt} className="w-full h-full" />
      ) : stage.type === "video" ? (
        <video
          src={stage.src}
          muted
          autoPlay
          className={`w-full h-full ${scale}`}
          playsInline
        />
      ) : null}
    </motion.div>
  );
};

const PartnerWithUs = () => {
  const [currentStage, setCurrentStage] = useState(0);
  const containerRef = useRef(null);
  const observerRefs = useRef([]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });

  const lineHeight = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = Number(entry.target.id);
            setCurrentStage(id - 1);
          }
        });
      },
      { threshold: 0.5 }
    );

    observerRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-grey-500 text-neutral-green-500">
        <main className="pt-20 pb-20 flex" ref={containerRef}>
          <div className="w-1/2 fixed top-20 left-0 h-[calc(100vh-5rem)] flex items-center justify-center p-8 pr-20">
            <ProductEvolutionContent currentStage={currentStage} />
          </div>
          <div className="w-1/2 ml-auto relative">
            <motion.div
              className="absolute left-0 top-0 w-1 bg-red-500 origin-top z-10"
              style={{ height: lineHeight }}
            />
            {productStages.map((stage, index) => (
              <div
                key={stage.id}
                id={stage.id.toString()}
                ref={(el) => (observerRefs.current[index] = el)}
                className="min-h-screen flex items-center justify-center p-4"
              >
                <ProductStageImage
                  stage={stage}
                  isActive={currentStage === index}
                />
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default PartnerWithUs;
