import { useState, useEffect, useRef } from "react";
import NavBar from "../components/NavBar/NavBar2";
import FirstSection from "../components/Partner/FirstSection";
import SecondSection from "../components/Partner/SecondSection";
import EmpathyProcessSection from "../components/Partner/EmpathyProcessSection";
import FinalSection from "../components/Partner/FinalSection";

export default function CreativePartnerLandingPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isFloating, setIsFloating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const finalSectionRef = useRef(null);

  const pipelineSteps = [
    { title: "Product's Pipeline", icon: "Zap" },
    { title: "Empathy Infusion", icon: "Sparkles" },
    { title: "Growth Acceleration", icon: "Rocket" },
    { title: "Market Bloom", icon: "Users" },
  ];

  useEffect(() => {
    const floatInterval = setInterval(() => {
      setIsFloating((prev) => !prev);
    }, 2000);
    return () => clearInterval(floatInterval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        setCurrentStep((prev) => (prev + 1) % pipelineSteps.length);
      }, 2000);
    } else {
      setCurrentStep(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isVisible, pipelineSteps.length]);

  return (
    <>
      <NavBar />
      <div className="flex flex-col min-h-screen bg-grey-100 ">
        <main className="flex-1">
          <FirstSection finalSectionRef={finalSectionRef}/>
          <SecondSection />
          <EmpathyProcessSection
            pipelineSteps={pipelineSteps}
            currentStep={currentStep}
            sectionRef={sectionRef}
          />
          <FinalSection ref={finalSectionRef}/>
        </main>
      </div>
    </>
  );
}
