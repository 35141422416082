// utils/productUtils.js

import { BASE_URL } from "../Global.js";

// import Cookies from 'js-cookie';

// export function saveProductToCookies(product) {
//     let savedProducts = JSON.parse(Cookies.get('savedProducts') || '[]');
//     savedProducts.push(product);
//     Cookies.set('savedProducts', JSON.stringify(savedProducts), { expires: 7 });
// }

// export function getSavedProductsFromCookies() {
//     return JSON.parse(Cookies.get('savedProducts') || '[]');
// }

export function fetchAndSendSavedProductsToApi() {
  const savedEventProducts = JSON.parse(
    localStorage.getItem("eventData") || "[]"
  );
  const savedSpaceProducts = JSON.parse(
    localStorage.getItem("spaceData") || "[]"
  );

  const email = window.localStorage.getItem("email");
  const prompt = window.localStorage.getItem("prompt");

  const dataToSend = {
    event: savedEventProducts,
    space: savedSpaceProducts,
    email: email,
    prompt: prompt,
  };
  console.log(dataToSend);

  if (savedEventProducts.length > 0 || savedSpaceProducts.length > 0) {
    // Send saved products to API
    fetch(`${BASE_URL}/api/user/event-space`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Saved products sent to API successfully");
          localStorage.removeItem("eventData");
          localStorage.removeItem("spaceData");
        } else {
          console.error("Failed to send saved products to API");
        }
      })
      .catch((error) => {
        console.error("Error sending saved products to API:", error);
      });
    console.log("data sent");
  } else {
    console.log("No saved products to send to API");
  }
}
