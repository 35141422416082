import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import TheTouchingHands from "../../Assets/Images/The Touching Hands.png";
import { useRef } from "react";

export default function FirstSection({ finalSectionRef }) {
  const scrollToFinalSection = () => {
    if (finalSectionRef.current) {
      finalSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 relative overflow-hidden">
      <div className="absolute inset-0 opacity-10 flex justify-end items-center">
        <img
          src={TheTouchingHands}
          className="w-full h-full object-cover"
          alt="The Gifting Lady"
        />
      </div>
      <div className="container px-4 md:px-6 relative">
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="space-y-2">
            <h1 className="font-open-sans text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none bg-clip-text text-transparent bg-gradient-to-r from-red-600 via-orange-600 to-green-600">
              Cultivate Empathy, Harvest Success
            </h1>
            <p className="mx-auto max-w-[700px] text-gray-700 md:text-xl dark:text-neutral-green-400">
              Join our innovation mission in revolutionizing the products with
              empathetic enhancement, reaching new heights in the market
              ecosystem.
            </p>
          </div>
          <div className="space-x-4">
            <Button
              onClick={scrollToFinalSection}
              className="bg-gradient-to-r from-red-600 rounded-3xl to-orange-600 text-grey-500 hover:from-red-700 hover:to-orange-700"
            >
              Partner with Ciqler
            </Button>
            <Link to="/magic">
              <Button
                variant="outline"
                className="border-red-600 text-red-600 rounded-3xl hover:bg-red-500 hover:text-grey-100"
              >
                Explore Our Magic
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
