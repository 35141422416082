import axios from "axios";
import { BASE_URL } from "../Global";

// BASE_URL="https://ds5p34fp5g.execute-api.us-east-1.amazonaws.com"

//Login
export const login = async (email, password) => {
  const response = await fetch(`${BASE_URL}/api/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  return response;
};

//SignUp
export const register = async (email, password) => {
  const response = await fetch(`${BASE_URL}/api/user/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  return response;
};

//Forget Password
export const forgetPassword = async (email) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/forget-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

//Reset Password
export const resetPassword = async (password, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/reset-password`,
      { password },
      { params: { token } }
    );
    return { success: true, message: response.data.message };
  } catch (error) {
    return { success: false, message: error.response.data.error };
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


//Send Feedback API

export const sendRatingFeedback = async (id, cookie, type, feedback)=>{
  try {
    const response = await axios.post(`${BASE_URL}/api/wpw/feedback`, {
      rating: id,
      userCookie: cookie,
      type: type,
      feedback,
    });
    return response
  } catch (error) {
    return error;
  }}