import { React, useState, useEffect, useRef } from "react";
import NavBar from "../components/NavBar/NavBar2.jsx";
import Body from "../components/HomePage/Body.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Profile from "../components/Profile/EventsAndSpaces/Profilepin.jsx";

const ProfilePage = () => {
  return (
    <div>
      <NavBar />
      <Profile />
      <Footer />
    </div>
  );
};

export default ProfilePage;
