import React, { useEffect, useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { gapi } from "gapi-script";

const LoginSignup = () => {
  const [isSignIn, setIsSignIn] = useState(true);

  return (
    <div className="min-h-screen flex items-center justify-center bg-grey-100 py-5">
      {/* <div className="w-full max-w-md space-y-8 p-6 bg-white rounded-lg shadow-lg"> */}
      {isSignIn ? (
        <SignIn setIsSignIn={setIsSignIn} />
      ) : (
        <SignUp setIsSignIn={setIsSignIn} />
      )}
    </div>
    // </div>
  );
};

export default LoginSignup;
