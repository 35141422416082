import { useState } from "react";
import { BASE_URL } from "../../Global";
import { XIcon } from "../Icons/Icons.jsx";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import useFavoriteStore from "../../Store/FavoriteStore.jsx";

export default function Component(props) {
  const [formData, setFormData] = useState({
    eventName: "",
    eventDate: "",
    eventTime: "00:00",
    eventLocation: "",
    eventDescription: "",
    eventImage: null,
    category: "", // Single value for event type
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [showOptionalFields, setShowOptionalFields] = useState(false); // State for toggling optional fields
  const { handleAddingNewSpaceOrEvent } = useFavoriteStore();
  const categoryList = [
    "Birthday",
    "Wedding",
    "Anniversary",
    "Graduation",
    "New Born",
    "Engagement",
    "Holiday",
    "Cultural",
    "Seasonal",
    "Professional",
  ];

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;

    if (type === "radio") {
      setFormData((prevData) => ({ ...prevData, category: value }));
    } else if (name === "eventImage") {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formDataToSave = { ...formData };

    formDataToSave.eventName = formDataToSave.eventName.trimEnd();

    if (formData.eventImage) {
      try {
        const base64Image = await convertToBase64(formData.eventImage);
        formDataToSave.eventImage = base64Image;
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }

    try {
      const response = await handleAddingNewSpaceOrEvent(
        "events",
        formDataToSave,
        []
      );
      console.log(response);
      if (response.status === 400 || response === 400) {
        setErrorMessage(
          "Event Already Exists, please create one with a different name, or date"
        );
      } else {
        props.setNewEventIsOpen(false);
        props.handleFetchEvents();
        props.setSelectedEvent(formData.eventName);
        props.setEventOpen(false);
        // props.handleEventClick("events", formData.eventName, formData.eventDate);

        // props.handleEventSelection(formData.eventName);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto bg-gradient-to-b from-red-50 to-grey-100 p-8 rounded-3xl shadow-2xl z-10 max-h-[80vh] flex flex-col"
    >
      <button
        onClick={() => props.setNewEventIsOpen(false)}
        className="absolute top-2 right-2 p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition duration-300"
      >
        <XIcon className="w-5 h-5 text-gray-700" />
        <span className="sr-only">Close</span>
      </button>
      <div className="p-6 flex-1 overflow-y-auto">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">
          Create New Event
        </h2>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex-1 space-y-2">
              <label
                htmlFor="eventName"
                className="block text-sm font-medium text-gray-700 text-left"
              >
                <span className="text-red-500">*</span> Name
              </label>
              <input
                id="eventName"
                name="eventName"
                value={formData.eventName}
                onChange={handleChange}
                placeholder="Enter event name"
                className="block w-full px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div className="flex-1 space-y-2">
              <label
                htmlFor="eventDate"
                className="block text-sm font-medium text-gray-700 text-left"
              >
                <span className="text-red-500">*</span> Date
              </label>
              <input
                type="date"
                id="eventDate"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                className="block w-full px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex-1 space-y-2">
              <label
                htmlFor="eventTime"
                className="block text-sm font-medium text-gray-700 text-left"
              >
                <span className="text-red-500">*</span> Time
              </label>
              <input
                id="eventTime"
                name="eventTime"
                type="time"
                value={formData.eventTime}
                onChange={handleChange}
                className="block w-full px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
          </div>

          {/* Toggle Button for Optional Fields */}
          <div
            className="flex items-center text-red-400 cursor-pointer"
            onClick={() => setShowOptionalFields(!showOptionalFields)}
          >
            {showOptionalFields ? (
              <FaAngleUp className="mr-2" />
            ) : (
              <FaAngleDown className="mr-2" />
            )}
            {showOptionalFields
              ? "Hide Optional Fields"
              : "Show Optional Fields"}
          </div>

          {/* Optional Fields */}
          {showOptionalFields && (
            <div className="flex flex-col gap-6 ">
              <div className="flex-1 space-y-2">
                <label
                  htmlFor="eventLocation"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Location
                </label>
                <input
                  id="eventLocation"
                  name="eventLocation"
                  value={formData.eventLocation}
                  onChange={handleChange}
                  placeholder="Enter event location"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="eventDescription"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Description
                </label>
                <textarea
                  id="eventDescription"
                  name="eventDescription"
                  value={formData.eventDescription}
                  onChange={handleChange}
                  rows={4}
                  placeholder="Provide details about the event"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                />
              </div>

              {/* Uncomment this section if you want to include an image upload */}
              {/* <div className="space-y-2">
                <label
                  htmlFor="eventImage"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Event Image
                </label>
                <input
                  type="file"
                  id="eventImage"
                  name="eventImage"
                  accept="image/*"
                  onChange={handleChange}
                  className="block w-full border border-gray-300 rounded-full py-2 px-4 text-gray-700"
                />
              </div> */}
            </div>
          )}
        </div>
        <div className="space-y-2 mt-6">
          <label className="block text-sm font-medium text-gray-700 text-left">
            <span className="text-red-500">*</span> Event Type
          </label>
          <div className="flex flex-wrap gap-2">
            {categoryList.map((type) => (
              <label
                key={type}
                className={`inline-flex items-center px-2.5 py-2 border border-red-500 rounded-full cursor-pointer ${
                  formData.category === type
                    ? "bg-red-600 text-white"
                    : "bg-white text-neutral-green-500-700"
                }`}
              >
                <input
                  type="radio"
                  name="category"
                  value={type}
                  checked={formData.category === type}
                  onChange={handleChange}
                  className="hidden"
                />
                <span className="text-xs">{type}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="mt-5">
          {errorMessage && (
            <p className="text-red-500 text-sm pt-2">{errorMessage}</p>
          )}{" "}
          <button
            type="submit"
            className={` w-full px-20 py-2.5 mt-1  rounded-full text-white font-medium ${
              formData.eventName &&
              formData.eventDate &&
              formData.category &&
              formData.eventTime
                ? "bg-red-600 hover:bg-red-700"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={
              !(formData.eventName && formData.eventDate && formData.category)
            }
          >
            Add Event
          </button>
        </div>
      </div>
    </form>
  );
}
