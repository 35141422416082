import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useEventStore from "../../../Store/EventStore.jsx";
import Dropdown from "./DropDown.jsx";
import ViewToggleButtons from "./ViewToggleButtons.jsx";
import EventCard from "./EventCard.jsx";
import ListViewEventCard from "./ListViewEventCard.jsx";
import AddableEventCard from "./AddableEventCard.jsx";
import Popup from "./DeleteEventPopUp.jsx";
import { FaPlus } from "react-icons/fa";
import NewEventForm from "../../Product/NewEventForm.jsx";
import SpaceCard from "./SpaceCard.jsx";
import FeaturedEventsComponent from "./FeaturedEvents/FeaturedEventsComponent.jsx";

// Function component for Profile
function Profile() {
  // State variables
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortBy, setSortBy] = useState("eventDate");
  const [viewType, setViewType] = useState("grid");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [eventToDelete, setEventToDelete] = useState("");
  const history = useHistory();
  const [newEventIsOpen, setNewEventIsOpen] = useState(false);
  const [type, setType] = useState("");
  const {
    fetchEvents,
    handleAddEvent,
    handleDeletingEventsOrSpaces,
    events,
    spaces,
    selectEvent,
    fetchFeaturedEvents,
    featuredEvents,
  } = useEventStore();

  // Fetch events and featured events on component mount
  useEffect(() => {
    fetchEvents();
    fetchFeaturedEvents();
  }, []);

  // Memoized filtered events based on selected category and sort criteria
  const filteredEvents = useMemo(() => {
    let filtered = [...events];
    if (selectedCategory !== "all") {
      filtered = filtered.filter(
        (event) => event.category === selectedCategory
      );
    }
    filtered = filtered.sort((a, b) => {
      if (sortBy === "eventDate") {
        return new Date(a.eventDate) - new Date(b.eventDate);
      } else if (sortBy === "popularity") {
        return (b.featured ? 1 : 0) - (a.featured ? 1 : 0);
      }
      return 0;
    });
    return filtered;
  }, [events, selectedCategory, sortBy]);

  // Filter featured events not present in filtered events
  const featuredFilterEvent = featuredEvents.filter(
    (event) => !filteredEvents.some((e) => e.eventName === event.eventName)
  );

  // Handle click to delete event or space
  const handleDeleteClick = (type, name) => {
    setEventToDelete(name);
    setType(type);
    setIsPopupVisible(true);
  };

  // Handle confirmation of deletion
  const handleConfirm = () => {
    handleDeletingEventsOrSpaces(type, eventToDelete);
    setIsPopupVisible(false);
  };

  // Handle cancellation of deletion
  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  // Handle redirection to event or space details
  const handleRedirect = (type, giftID, eventName) => {
    selectEvent(type, eventName);
    // localStorage.setItem("SelectedEvent", eventName);
    history.push(`/${type}/${eventName}`);
  };

  return (
    <div className="flex flex-col min-h-dvh bg-grey-500">
      <main className="container px-4 md:px-6 py-12 md:py-20">
        <div className="grid gap-8 md:grid-cols-[1fr_300px] md:gap-12">
          <div>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-4">
                <Dropdown
                  label={
                    selectedCategory === "all" ? "All Events" : selectedCategory
                  }
                  options={[
                    { value: "all", label: "All Events" },
                    ...events.reduce((acc, event) => {
                      if (!acc.some((e) => e.value === event.category)) {
                        acc.push({
                          value: event.category,
                          label: event.category,
                        });
                      }
                      return acc;
                    }, []),
                  ]}
                  selectedValue={selectedCategory}
                  onSelect={setSelectedCategory}
                />
                <Dropdown
                  label={sortBy === "eventDate" ? "Date" : "Popularity"}
                  options={[
                    { value: "eventDate", label: "Date" },
                    { value: "popularity", label: "Popularity" },
                  ]}
                  selectedValue={sortBy}
                  onSelect={setSortBy}
                />
              </div>
              {/* <ViewToggleButtons
                viewType={viewType}
                setViewType={setViewType}
              /> */}
            </div>
      <FeaturedEventsComponent events={featuredFilterEvent} />


            {filteredEvents.length > 0 && (
              <h3 className="mt-6 mb-3 font-medium text-neutral-green-500 ">
                Events
              </h3>
            )}
            <div
              className={`grid gap-6 ${
                viewType === "grid"
                  ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
                  : "grid-cols-1"
              }`}
            >
              {filteredEvents.length !== 0 &&
                filteredEvents.map((event) =>
                  viewType === "grid" ? (
                    <EventCard
                      key={event.id}
                      eventData={event}
                      onDelete={() =>
                        handleDeleteClick("events", event.eventName)
                      }
                      onRedirect={handleRedirect}
                    />
                  ) : (
                    <ListViewEventCard
                      key={event.id}
                      event={event}
                      onDelete={() =>
                        handleDeleteClick("events", event.eventName)
                      }
                      onRedirect={handleRedirect}
                    />
                  )
                )}
              <div className="flex flex-wrap gap-4">
                {" "}
                {filteredEvents.length === 0 && <h3>No events created yet.</h3>}
                <button
                  className="flex items-center px-4 py-2 bg-gradient-to-r from-grey-100 to-grey-200 text-neutral-green-500 rounded-2xl shadow-md hover:bg-gradient-to-br hover:from-red-50 hover:to-grey-100 hover:text-red-500 transition-colors duration-300 max-w-60 w-full min-h-72"
                  aria-label="Add Event"
                  onClick={() => setNewEventIsOpen(true)}
                >
                  <FaPlus className="mr-2 text-lg" />
                  <span className="font-medium">Create Event</span>
                </button>
              </div>
              {newEventIsOpen && (
                <div
                  className="fixed inset-0 flex items-center justify-center bg-neutral-green-700 bg-opacity-50 z-10 "
                  onClick={() => setNewEventIsOpen(false)}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <NewEventForm
                      setNewEventIsOpen={setNewEventIsOpen}
                      handleFetchEvents={fetchEvents}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="
              w-full"
            >
              <h3 className="mt-6 mb-3 font-medium text-neutral-green-500 ">
                Spaces
              </h3>
              <div
                className={`grid gap-3 ${
                  viewType === "grid"
                    ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-2"
                    : "grid-cols-1"
                }`}
              >
                {spaces.map((space) => (
                  <SpaceCard
                    key={space.id}
                    spaceData={space}
                    onDelete={() =>
                      handleDeleteClick("spaces", space.spaceName)
                    }
                    onRedirect={handleRedirect}
                  />
                ))}
              </div>
            </div>
          </div>

          {isPopupVisible && (
            <Popup
              eventName={eventToDelete}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}

          {/* <div className="shadow-md p-4 items-baseline">
            <h2 className="text-lg font-semibold">Featured Events</h2>
            {featuredFilterEvent.map((event) => (
              <AddableEventCard
                key={event.id}
                event={event}
                onAdd={() => handleAddEvent(event)}
              />
            ))}
          </div> */}
        </div>
      </main>
    </div>
  );
}

export default Profile;
