// GoogleSignIn.js
import React from "react";
import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login";

const FacebookSignin = () => {
  const responseFacebook = (response) => {
    console.log("Facebook login response:", response);
  };

  return (
    <div>
      <FacebookLogin
        appId="3375331082774249"
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="btn btn-facebook"
        icon="fa-facebook"
        textButton="&nbsp;&nbsp;Login with Facebook"
        autoLoad={false}
      />
    </div>
  );
};

export default FacebookSignin;
