import React from "react";
import {
  FaRegFrown,
  FaRegMeh,
  FaRegSmile,
  FaRegGrin,
  FaRegFrownOpen,
} from "react-icons/fa";

const FacesDisplay = ({ selectedRating, onRatingClick }) => {
  const textsize = "text-6xl";
  const faces = [
    { id: 1, icon: <FaRegFrown className={textsize} />, label: "Sad" },
    { id: 2, icon: <FaRegFrownOpen className={textsize} />, label: "Slightly Sad" },
    { id: 3, icon: <FaRegMeh className={textsize} />, label: "Neutral" },
    { id: 4, icon: <FaRegSmile className={textsize} />, label: "Happy" },
    { id: 5, icon: <FaRegGrin className={textsize} />, label: "Very Happy" },
  ];

  return (
    <div className="flex flex-row">
      {faces.map((face) => (
        <div
          key={face.id}
          onClick={() => onRatingClick(face.label, face.id)}
          className={`relative cursor-pointer p-2 rounded-full group ${
            selectedRating === face.id ? "text-green-500" : "text-green-600"
          }`}
          style={{ transition: "color 0.3s" }}
        >
          <div className="flex justify-center items-center">{face.icon}</div>
          <div className="absolute hidden group-hover:block bg-red-600 text-white py-1 px-3 rounded mb-1.5 top-full text-center font-sans z-50 left-1/2 transform -translate-x-1/2">
            <span className="text-sm font-semibold">{face.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FacesDisplay;
