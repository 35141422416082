import React from "react";

 export const Button = ({

  children,
  variant = "default",
  size = "md",
  className = "",
  ...props
}) => {
  const baseStyles =
    "inline-flex items-center justify-center  font-medium transition-colors focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:pointer-events-none";
  const variants = {
    icon: "",
    default: "bg-red-400 text-white hover:bg-red-500",
    ghost: "bg-transparent text-gray-500 hover:bg-gray-100 p-2",
    outline: "text-center border-2 hover:bg-grey-100 ",
    primary: "bg-green-500 text-white hover:bg-green-600",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    unique:
      "justify-center items-center px-16 py-2 mt-1 text-center text-red-400 whitespace-nowrap bg-grey-500 border-red-400 border-2 leading-[150%] rounded-[100px] shadow-sm",
  };
  const sizes = {
    sm: "px-2.5 py-1.5 text-sm",
    md: "px-4 py-2",
    lg: "px-5 py-2.5 text-lg",
    icon: "p-2.5",
    dropdown: "py-2 px-16",
    null: "",
  };

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
