// components/ScrollButtons.jsx
import React from "react";
import { Button } from "../../../ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

const ScrollButtons = ({ showLeftScroll, showRightScroll, onScroll }) => (
  <>
    {showLeftScroll && (
      <Button
        className="absolute left-2 top-1/2 transform -translate-y-1/2 rounded-full w-10 h-10 p-0 text-gray-800 hover:bg-red-400"
        variant="icon"
        size="null"
        onClick={() => {
          console.log("Left button clicked");
          onScroll("left");
        }}
      >
        <ChevronLeft className="w-6 h-6" />
      </Button>
    )}
    {showRightScroll && (
      <Button
        className="absolute right-2 top-1/2 transform -translate-y-1/2 rounded-full w-10 h-10 p-0 text-gray-800 hover:bg-red-400"
        variant="icon"
        size="null"
        onClick={() => {
          console.log("Right button clicked");
          onScroll("right");
        }}
      >
        <ChevronRight className="w-6 h-6" />
      </Button>
    )}
  </>
);


export default ScrollButtons;
