import create from 'zustand';
import { persist } from 'zustand/middleware';
import useFavoriteStore from './FavoriteStore';

const useAuthStore = create(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      token:null, 
      login: (user, token) => set({ isAuthenticated: true, user , token }),
      logout: () => {
        localStorage.setItem('prompt', '');
        useFavoriteStore.setState({ events: [], spaces: [] });
        set({ isAuthenticated: false, user: null });
      },
    }),
    {
      name: 'auth-storage', // name of the item in localStorage
      
    }
  )
);

export default useAuthStore;
