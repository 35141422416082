export const validateEmail = (email) => {
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    } else if (!emailFormat.test(email)) {
      return "Email is not in the correct format";
    }
    return "";
  };
  
  export const validatePassword = (password) => {
    const strongPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!password) {
      return "Password is required";
    } else if (!strongPassword.test(password)) {
      return "Password should be at least 8 characters long and contain at least one number, one lowercase letter, and one uppercase letter";
    }
    return "";
  };
  
  export const validateConfirmPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      return "Passwords do not match";
    }
    return "";
  };
  