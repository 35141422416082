import React, { useState } from "react"; // Added React import
import { Badge } from "../../ui/badge.jsx";
import { Button } from "../../ui/button.jsx";
import { Calendar, Heart, Share2, Edit2, Check, Trash2 } from "lucide-react";
import useEventStore from "../../../Store/EventStore.jsx";

// Component to render action buttons
const ActionButtons = ({ onEdit, onDelete, isEditing, isLiked, onLike }) => (
  <div className="flex justify-center gap-6 w-full items-end mt-3">
    <div className="flex gap-1">
      <Button
        onClick={onEdit}
        size="icon"
        variant=""
        className={`rounded-full bg-gray-200 hover:bg-gray-300 text-gray-800 p-1.5 ${
          isEditing ? "bg-green-600 text-grey-100 hover:bg-green-700  " : ""
        }`}
        aria-label={isEditing ? "Save changes" : "Edit space details"}
      >
        {isEditing ? (
          <Check className="h-3 w-3" />
        ) : (
          <Edit2 className="h-3 w-3" />
        )}
      </Button>
    </div>
    <div className="flex gap-2">
      {/* <Button
        onClick={onLike}
        size="icon"
        variant=""
        className={`rounded-full transition-all duration-300 p-1.5 ${
          isLiked
            ? "bg-red-500 text-white"
            : "bg-gray-200 hover:bg-gray-300 text-gray-600"
        }`}
        aria-label={isLiked ? "Unlike space" : "Like space"}
      >
        <Heart className="h-3 w-3" />
      </Button> */}
      {/* <Button
        size="icon"
        variant=""
        className="rounded-full bg-gray-200 hover:bg-gray-300 text-gray-600 p-1.5"
        aria-label="Share space"
      >
        <Share2 className="h-3 w-3" />
      </Button> */}
      <Button
        onClick={onDelete}
        size="icon"
        variant=""
        className="rounded-full bg-gray-200 hover:bg-gray-300 text-red-500 p-1.5"
        aria-label="Delete space"
      >
        <Trash2 className="h-3 w-3" />
      </Button>
    </div>
  </div>
);

const SpaceCard = ({ spaceData, onDelete, onRedirect }) => {
  // Changed spaceCard to SpaceCard
  console.log(spaceData);
  const [isLiked, setIsLiked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(spaceData.spaceName);
  const type = "spaces";
  const originalName = spaceData.spaceName

  const { handleEdit: handleEditApi} = useEventStore()

  const handleEdit = (e) => {
    e.stopPropagation(); // Prspace redirect on button click
    if (isEditing) {
      // Save the edited data
      spaceData.spaceName = editedName;

      handleEditApi(type, originalName, editedName);

    }
    setIsEditing(!isEditing);
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prspace redirect on button click
    if (onDelete) onDelete();
  };

  const handleLike = (e) => {
    e.stopPropagation(); // Prspace redirect on button click
    setIsLiked(!isLiked);
  };

  const handleRedirect = () => {
    if (!isEditing && onRedirect)
      onRedirect(type, spaceData.giftID, spaceData.spaceName);
  };

  return (
    <div
      className="bg-gradient-to-r from-red-50 to-grey-200 backdrop-blur-md rounded-2xl p-4 shadow-md border-1 max-w-60 min-h-40 w-full cursor-pointer flex flex-col h-full justify-center items-center"
      onClick={handleRedirect}
    >
      {/* Container for the main content */}
      <div className="flex-grow flex justify-center items-center gap-1 mb-2 w-full ">
        <div className="w-full">
          {isEditing ? (
            <div
              onClick={(e) => e.stopPropagation()}
              className="justify-center items-center"
            >
              <input
                type="text"
                name="spaceName"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="text-sm font-semibold mb-1 w-full bg-transparent border-b border-gray-300 focus:outline-none focus:border-blue-500"
              />
            </div>
          ) : (
            <div className="flex flex-wrap align-middle justify-center items-center">
              <h2 className="text-lg font-semibold mb-1 text-gray-800">
                {spaceData.spaceName}
              </h2>
            </div>
          )}
          <div className="flex gap-2 w-full flex-wrap mt-2 align-middle justify-center items-center">
            {spaceData.giftID && (
              <Badge className="text-xs font-semibold bg-gradient-to-r from-red-500/80 to-neutral-green-500/80 text-white px-2 py-1 rounded-full">
                {spaceData.giftID.length} saved products
              </Badge>
            )}
          </div>
        </div>
      </div>
      {/* Action buttons are always at the end */}
      <ActionButtons
        onEdit={handleEdit}
        onDelete={handleDelete}
        isEditing={isEditing}
        isLiked={isLiked}
        onLike={handleLike}
        className="mt-auto " // Ensures ActionButtons stay at the bottom of the component
      />
    </div>
  );
};

export default SpaceCard; // Changed spaceCard to SpaceCard
