import { useState } from "react";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../Assets/Images/Ciqlercompined2024.png";
import useAppStore from "../../Store/Store.jsx";
import { CgProfile } from "react-icons/cg";
import { FaHeart } from "react-icons/fa";
import useAuthStore from "../../Store/AuthStore.jsx";

export default function NavBar() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [accountDropdown, setAccountDropdown] = useState(false);
  const Events = localStorage.getItem("saved_event");
  const [showMessage, setShowMessage] = useState(true);
  const { logout, isAuthenticated: isLoggedIn } = useAuthStore();

  const history = useHistory();
  const handleClick = () => {
    // console.log("Image clicked!");
    setSearchText(""); // Set searchText to empty string when image is clicked
    setTag("");
  };

  const { searchText, setSearchText, tag, setTag } = useAppStore(); // Destructure searchText and setSearchText from useAppStore

  // useEffect(() => {
  //   const handleLoggedin = () => {
  //     const isLoggedIn = JSON.parse(localStorage.getItem("loggedin"));
  //     if (isLoggedIn) {
  //       setIsLoggedIn(true);
  //     }
  //   };
  //   handleLoggedin();
  // }, []);

  return (
    <header className="flex h-20 w-full items-center px-4 md:px-6 bg-grey-100 border-b border-gray-300 ">
      <Link to="/" className="flex items-center">
        {/* Replace with your logo or icon */}
        <img
          className="max-w-full aspect-[4.76] w-[130px] hover:cursor-pointer"
          src={logo}
          alt="Logo"
          onClick={() => {
            handleClick(); // Call handleClick function when image is clicked
          }}
        />{" "}
      </Link>
      <div className="flex-1 hidden justify-end p-6 lg:flex">
        {/* Your navigation menu items for larger screens */}
        <nav className="flex space-x-4">
          {/* <Link href="#">About</Link>
          <Link href="#">Services</Link> */}
          <Link
            to="/partner"
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-grey-100 bg-red-300 hover:bg-red-400"
          >
            Partner with us 
          </Link>
        </nav>
      </div>
      <div className="ml-auto flex gap-2">
        {isLoggedIn && !localStorage.getItem("eventData") && (
          <Link to="/profile">
            <button className="flex items-center border border-gray-300 rounded-full p-2 hover:bg-gray-100 ">
              <FaHeart className="h-5 w-5 text-red-400" />
              <span className="sr-only">Save to Event</span>
            </button>
          </Link>
        )}
        {(localStorage.getItem("eventData") ||
          localStorage.getItem("spaceData")) && (
          <div className="relative">
            <button
              className="flex items-center gap-2 border border-gray-300 rounded-full p-2 hover:bg-gray-100 mr-2"
              onClick={() => setShowMessage(!showMessage)}
            >
              <FaHeart className="h-5 w-5 text-red-400" />
              <span className="sr-only">Save to Event</span>
              <span className="text-neutral-green-500">
                {localStorage.getItem("eventData") &&
                  JSON.parse(localStorage.getItem("eventData")).length +
                    (localStorage.getItem("spaceData") &&
                      JSON.parse(localStorage.getItem("spaceData")).length)}
              </span>
            </button>
            {showMessage && (
              <div className="absolute right-0 top-12 z-10 w-[300px] rounded-md bg-white p-4 shadow-lg">
                <div className="space-y-2">
                  <h4 className="text-lg font-medium">Important Message</h4>
                  <p className="text-sm text-gray-500">
                    you have{" "}
                    <span className="text-red-400">
                      {localStorage.getItem("eventData") &&
                        JSON.parse(localStorage.getItem("eventData")).length +
                          (localStorage.getItem("spaceData") &&
                            JSON.parse(localStorage.getItem("spaceData"))
                              .length)}{" "}
                    </span>
                    unsaved events & spaces, and{" "}
                    <span className="text-red-400">
                      {localStorage.getItem("eventData") &&
                        JSON.parse(localStorage.getItem("eventData")).reduce(
                          (total, event) =>
                            total + (event.giftID ? event.giftID.length : 0),
                          0
                        ) +
                          (localStorage.getItem("spaceData") &&
                            JSON.parse(
                              localStorage.getItem("spaceData")
                            ).reduce(
                              (total, space) =>
                                total +
                                (space.giftID ? space.giftID.length : 0),
                              0
                            ))}
                    </span>{" "}
                    unsaved products.{" "}
                    <span
                      onClick={() => history.push("/login")}
                      className="text-red-500 cursor-pointer"
                    >
                      Login
                    </span>{" "}
                    in to have them saved.
                  </p>
                  <div className="flex justify-end">
                    <button
                      className="bg-transparent text-red-500 px-4 py-2 rounded hover:bg-gray-100"
                      onClick={() => setShowMessage(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isLoggedIn && (
          // User dropdown menu for logged-in users
          <div className="relative">
            <button
              onClick={() => setAccountDropdown(!accountDropdown)}
              className={`text-gray-600 h-full hover:text-gray-900 focus:outline-none ${
                isLoggedIn ? "" : "hidden"
              }`}
            >
              <CgProfile size={30} />
            </button>
            {/* Dropdown content */}
            {accountDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-grey-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10">
                {
                  <a
                    href="/Profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    My Account
                  </a>
                  /*<a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Settings
                </a> */
                }
                <button
                  onClick={() => {
                    // setIsLoggedIn(false);
                    logout();
                    localStorage.removeItem("token");
                    localStorage.removeItem("loggedin");
                    setSearchText("");
                    // history.push("/"); // Redirect to login page after logout
                  }}
                  className="block w-full px-4 py-2 text-sm text-grey-900 hover:bg-gray-200 text-left rounded-lg"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
        {/* {!isLoggedIn && (
          // Login button for not logged-in users
          <button
            onClick={() => {
              history.push("/login");
            }}
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-grey-100 bg-red-300 hover:bg-red-400"
          >
            Login{" "}
          </button>
        )} */}
        {/* Hamburger menu for small screens */}
        <button onClick={() => setIsNavOpen(!isNavOpen)} className="lg:hidden">
          <svg
            className="h-6 w-6 text-gray-600 hover:text-gray-900 focus:outline-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile navigation menu */}
      {isNavOpen && (
        <div className="lg:hidden absolute top-0 left-0 h-screen w-64 bg-grey-100 shadow-lg z-3">
          <nav className="pt-8 px-4">
            <ul className="space-y-4">
              <li>
                <Link to="/partner" className="hover:font-semibold">
                  Partner
                </Link>{" "}
              </li>
              {/* <li>
                <Link href="#">Services</Link>
              </li>
              <li>
                <Link href="#">Contact</Link>
              </li> */}
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}
