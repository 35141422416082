import React from "react";
import PlaceholderImage from "../../Assets/Images/SadGift.png";
const NoSearchResults = ({ query }) => {
  return (
    <div className="flex flex-col items-center justify-center  sm:justify-center lg:justify-start lg:mt-24 min-h-screen text-center bg-stone-50 ">
      <img src={PlaceholderImage} alt="No results" className="w-72 h-72 mb-4" />
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Whoops! It looks like we’re all out of ideas for that search.{" "}
      </h2>
      <p className="text-lg text-gray-600">
        Finding the perfect gift can be tricky. Try Using fewer or different
        keywords. <span className="font-semibold"></span> <br />
      </p>
    </div>
  );
};

export default NoSearchResults;
