// components/EventCard.jsx
import React from "react";
import { Button } from "../../../ui/button";
import { FaPlus } from "react-icons/fa";

const EventCard = ({ event, onAdd, isAdded }) => (
  <div className="w-[220px] h-[80px] shrink-0 relative group ">
    <div
      className={`absolute inset-0 rounded-full ${event.color} shadow-md transition-all duration-300 group-hover:scale-105 flex items-center bg-gradient-to-br from-red-50 to-gray-100`}
    >
      <div className="absolute left-4 w-12 h-12 rounded-full bg-white flex items-center justify-center">
        {/* <div className="text-3xl">{event.icon}</div> */}
        <Button
          className="absolute bg-grey-500 text-red-500 rounded-full h-12 w-12 flex items-center justify-center hover:text-red-600 transform hover:scale-110"
          onClick={() => onAdd(event)}
          disabled={isAdded}
          variant="icon"
          size="null"
        >
          <FaPlus className="w-3 h-3" />
        </Button>
      </div>
      <div className="ml-20 flex-grow ">
        <h3 className="text-xs font-semibold mb-1">{event.eventName}</h3>
        <p className="text-xs text-gray-600">
          {new Date(event.eventDate).toDateString()}
        </p>
      </div>
    </div>
  </div>
);

export default EventCard;
