// This is the Search Component Locked to the bottom of the screen
import { React, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom"; // Import useHistory and useLocation
import MentionEditor from "./suggestion.jsx";
import TipTapMention from "./TipTapMention.jsx";
import Cookies from "js-cookie";
import "../Styles/Search.scss";
import RecordButton from "./RecordButton.jsx";
import useAppStore from "../../Store/Store.jsx";
import Quill from "./Quill.jsx";
import Tiptap from "./TipTap.jsx";
import { useCurrentEditor } from "@tiptap/react";
import "../Styles/TipTap.scss";
import { MentionProvider } from "./MentionContext.jsx";
import RatingFaces from "../Rating/Rating.jsx";

const TextArea = ({ pageType }) => {
  // Destructuring properties from the custom hook useAppStore
  const {
    searchText,
    setSearchText,
    handleSearch,
    getWantedGifts,
    placeholders,
    placeholderIndex,
    rotatePlaceholder,
    tag,
    count,
    incrementCount,
  } = useAppStore();

  // State hooks for focus management and placeholder handling
  const [isFocused, setIsFocused] = useState(false);
  const [focusedPlaceholder, setFocusedPlaceholder] = useState("");
  const history = useHistory(); // Initialize useHistory
  const location = useLocation(); // Initialize useLocation
  const [buttonContent, setButtonContent] = useState("button");
  const [userInfo, setUserInfo] = useState({});

  // State to store button content
  const editorRef = useRef(null);

  const quillRef = useRef(null); // Define quillRef using useRef

  const EditorJSONPreview = () => {
    const { editor } = useCurrentEditor();
  };

  // useEffect(() => {
  //   // Get a cookie when the component mounts
  //   const cookie = Cookies.get()
  //   if (cookie) {
  //     setUserInfo(cookie);
  //   }
  //   console.log(userInfo)
  // }, []);

  // This effect hook is responsible for extracting the 'query' parameter from the URL,
  // decoding it, and then using it to set the search text and perform the search.
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    if (query) {
      const decodedQuery = decodeURIComponent(query);

      setSearchText(decodedQuery);
      performSearch(decodedQuery);
      // Perform search after setting the searchText
    }
  }, [location, setSearchText]);

  // This function performs the search operation by fetching the wanted gifts
  // and handling the search based on the provided searchText.
  const performSearch = async (searchText) => {
    const cookie = Cookies.get();
    await getWantedGifts(cookie, searchText);
    await handleSearch(searchText);
    incrementCount();
  };

  // Function to handle search button click, triggers two async functions
  const handleSearchButtonClick = async () => {
    if (pageType === "home") {
      history.push("/search"); // Redirect to search page
    }
    // await getWantedGifts(searchText); // Fetch gifts based on searchText
    // await handleSearch(searchText); // Perform search operation with searchText
    // performSearch();
    console.log(searchText);
    const encodedSearchText = encodeURIComponent(searchText);
    history.push(`/search?query=${encodedSearchText}`); // Redirect to search page with query params
    console.log("searchbuttonclick");
  };

  // Effect hook to rotate placeholder text at an interval when not focused
  useEffect(() => {
    const intervalId = setInterval(() => {
      // if (!isFocused) {
      rotatePlaceholder(); // Rotate to next placeholder if input is not focused
      // }
    }, 1500); // Interval set at 1500 milliseconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [rotatePlaceholder, isFocused]);

  // Function to handle focus event on textarea
  const handleFocus = () => {
    setIsFocused(true);
    setFocusedPlaceholder(placeholders[placeholderIndex]); // Set focused placeholder based on current index
  };

  // Function to handle blur event on textarea
  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setSearchText(content);
    console.log(content);
  };

  const handleAddButton = () => {
    // Logic to set button content
    setButtonContent("New Button");
  };

  // Determine additional class based on pageType
  const additionalClasses =
    pageType === "searchPage"
      ? "flex justify-center items-center min-h-32 "
      : pageType === "home"
      ? "h-40 bottom-7 px-2 py-1 w-11/12 flex flex-col justify-center rounded-3xl border-2 shadow-lg bg-stone-50 border-1 border-red-500"
      : "sticky top-0 z-10  bg-stone-50 w-9/11 justify-center items-center px-2.5 py-2 mb-auto mx-4 mt-9 rounded-3xl border border-solid border-red-500 shadow-md max-md:mb-auto";
  const additionalClasses2 =
    pageType === "searchPage"
      ? "fixed bottom-7 px-2 py-1 w-11/12 flex flex-col justify-center rounded-3xl border-solid shadow-md bg-stone-50 border-1 border-[color:var(--Color-Red-red-500,#AD1700)] "
      : pageType === "home"
      ? "h-full py-2 h-full pr-2.5 pt-4 w-full rounded-3xl max-md:max-w-full"
      : "";
  const additionalClasses3 =
    pageType === "searchPage"
      ? "h-full w-full py-2 flex gap-3 pr-2.5 w-full rounded-3xl max-md:max-w-full max-w-full max-h-full  "
      : pageType === "home"
      ? "flex gap-2.5 h-full "
      : "";
  const textAreaClass =
    pageType === "searchPage"
      ? "px-4 flex-auto my-auto text-base align-text-top font-bold leading-6 text-left bg-stone-50 text-neutral-green-500 focus:outline-none resize-none"
      : pageType === "home"
      ? "px-4 h-full flex-auto my-auto text-base align-text-top font-bold leading-6 text-left bg-stone-50 text-neutral-green-500 focus:outline-none resize-none"
      : "px-4 h-full flex-auto my-auto text-base align-text-top font-bold leading-6 text-left bg-stone-50 text-neutral-green-500 focus:outline-none resize-none";

  // Component JSX
  return (
    <div className={additionalClasses}>
      <div className={additionalClasses2}>
        {pageType === "searchPage" && count === 3 && <RatingFaces />}
        <div className={additionalClasses3}>
          <TipTapMention
            ref={editorRef}
            initialContent={searchText}
            placeholder={placeholders[placeholderIndex]}
            onContentChange={setSearchText}
            searchText={searchText}
            setSearchText={setSearchText}
            handleSearchButtonClick={handleSearchButtonClick}
            focusedPlaceholder={placeholders[placeholderIndex]}
            placeholders={placeholders}
            isFocused={isFocused}
            placeholderIndex={placeholderIndex}
            rotatePlaceholder={rotatePlaceholder}
            // addButton={<button onClick={handleClearContent}>Clear Content</button>} // Pass the button content as a prop
          />

          <div className="flex gap-2.5 justify-end bottom-0 items-end">
            {/* <RecordButton pageType={pageType} /> */}

            <div
              className="relative group flex justify-center items-center w-10 h-10 bg-red-500 rounded-3xl aspect-square border-2 border-red-500 active:bg-red-700 focus:bg-red-700 hover:cursor-pointer px-4"
              onClick={() => {
                handleSearchButtonClick(); // Trigger search on button click
              }}
            >
              <button>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 14.75L10.25 10.25M11.75 6.5C11.75 9.39949 9.39949 11.75 6.5 11.75C3.60051 11.75 1.25 9.39949 1.25 6.5C1.25 3.60051 3.60051 1.25 6.5 1.25C9.39949 1.25 11.75 3.60051 11.75 6.5Z"
                    stroke="#E6F4F4"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div class="absolute hidden group-hover:block bg-green-600 text-white py-1 px-3 rounded mb-1.5 bottom-full text-center font-sans z-50">
                <span class="text-sm font-semibold">Search</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default TextArea;
