// FilterIcon
export const FilterIcon = ({ className }) => (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707v5.172a1 1 0 01-1.707.707l-2-2a1 1 0 01-.293-.707v-3.172a1 1 0 00-.293-.707L3.293 6.707A1 1 0 013 6V4z"
      ></path>
    </svg>
  );
  

// ListOrderedIcon
export const ListOrderedIcon = ({ className }) => (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 6h13M8 12h13m-13 6h13M3 6h.01M3 12h.01M3 18h.01"
      ></path>
    </svg>
  );
  

// LayoutGridIcon
export const LayoutGridIcon = ({ className }) => (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3h7v7H3zM3 14h7v7H3zM14 3h7v7h-7zM14 14h7v7h-7z"
      ></path>
    </svg>
  );

  // SearchIcon
  export const SearchIcon = ({ className }) => (
    <svg className={className} viewBox="0 0 24 24" fill="currentColor">
      <path d="M15.5 14h-.79l-.28-.27a6.471 6.471 0 001.48-5.34C14.93 5.15 12.49 3 9.5 3S4.07 5.15 4.07 8.39c0 3.24 2.44 5.39 5.43 5.39 1.61 0 3.09-.59 4.25-1.57l.27.28v.79l4.99 4.98c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L15.5 14zm-6-5c-1.93 0-3.5-1.57-3.5-3.5S7.57 2 9.5 2s3.5 1.57 3.5 3.5S11.43 9 9.5 9z" />
    </svg>
  );
  
// ListIcon
export const ListIcon = ({ className }) => (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 6h13M8 12h13m-13 6h13M3 6h.01M3 12h.01M3 18h.01"
      ></path>
    </svg>
  );

  // SVG PlusIcon Component
  export const PlusIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M5 12h14" />
    <path d="M12 5v14" />
  </svg>
);

// SVG XIcon Component
export const XIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 6 6 18" />
    <path d="m6 6 12 12" />
  </svg>
);
