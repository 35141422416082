import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import xbutton from "../../Assets/Images/xbutton.png";
import "../Styles/SignInUpPopUp.scss";

function PopUp(props) {
  const [showPopUp, setShowPopUp] = useState(props.state);
  const togglePopUp = () => setShowPopUp(!showPopUp);
  const handleClick = () => {
    props.toggleState();
    setShowPopUp(!showPopUp);
  };

  const history = useHistory();

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-green-100 p-7 rounded-lg shadow-lg overflow-hidden z-50"
        ref={popupRef}
        onClick={props.onClose}
      >
        <div className="relative p-10" onClick={(e) => e.stopPropagation()}>
          <button
            className="absolute top-4 right-4"
            onClick={props.onClose}
            aria-label="Close"
          >
            <img src={xbutton} alt="Close" className="w-6 h-6" />
          </button>
          <h2 className="text-2xl font-bold m-4 text-neutral-green-500">
            Like what you See?
          </h2>
          <p className="text-neutral-green-500 m-6">
            Sign in to Like, and Save Gifts.
          </p>
          <div className="flex justify-around">
            <button
              className="bg-red-400 text-white px-4 py-2 rounded hover:bg-red-500"
              onClick={() => history.push("/login")}
            >
              Login
            </button>
            {/* <button
              className="bg-red-400 text-white px-4 py-2 rounded hover:bg-red-500"
              onClick={() => history.push("/login#signup-form")}
            >
              Sign Up
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
