import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/NavBar/NavBar2";
import LogSignInForm from "../components/Authentication/LoginSignup";
const Login = () => {
  return (
    <div>
      {/* <Navbar /> */}
      {/* <div className="log-div-sep"> */}
      <LogSignInForm />
      {/* </div> */}
    </div>
  );
};

export default Login;
