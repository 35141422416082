import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import Image404 from "../Assets/Images/AwkwardGift.png";

export default function Custom404() {
  return (
    <div className="min-h-screen bg-white text-gray-900 flex items-center justify-center p-4">
      <div className="relative w-full max-w-2xl flex flex-col items-center text-center">
        {/* 404 Text and Message */}
        <motion.h1
          className="text-9xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-400"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", damping: 20, stiffness: 100 }}
        >
          404
        </motion.h1>

        {/* Image at the top */}
        <div className="mb-8">
          <img
            src={Image404}
            alt="Custom 404 Graphic"
            className="w-64 h-64 object-contain rounded-lg"
          />
        </div>

        <motion.p
          className="text-2xl mb-8 text-gray-600"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Oops! You've ventured into uncharted digital territory.
        </motion.p>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <Link
            to="/"
            className="text-red-500 hover:text-red-600 transition-colors duration-300"
          >
            ← Return to Home
          </Link>
        </motion.div>
      </div>
    </div>
  );
}
