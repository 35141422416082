// ListViewEventCard.jsx
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card.jsx";
import { Badge } from "../../ui/badge.jsx";
import { FaTimes } from "react-icons/fa";

const ListViewEventCard = ({ event, onDelete, onRedirect }) => (
  <Card className="group hover:shadow-lg transition-shadow flex items-center">
    <CardHeader className="relative w-1/4 h-full">
      <img
        src={event.image || "path/to/placeholder.png"}
        alt={event.eventName}
        className="object-cover h-full w-full rounded-lg cursor-pointer"
        onClick={() => onRedirect(event.giftID, event.eventName)}
      />
    </CardHeader>
    <CardContent className="flex-grow p-4">
      <CardTitle className="text-lg font-medium break-words">
        {event.eventName}
      </CardTitle>
      <p className="text-gray-600">{event.eventDate}</p>
      <Badge className="mt-2">{event.category}</Badge>
    </CardContent>
    <button className="ml-4" onClick={onDelete}>
      <FaTimes className="w-5 h-5 text-gray-500" />
    </button>
  </Card>
);

export default ListViewEventCard;
