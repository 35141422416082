import "../Styles/TipTap.scss";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js"; // Import Fuse for fuzzy search
import Mention from "@tiptap/extension-mention"; // Import Mention extension
import tippy from "tippy.js"; // Import tippy for tooltips
import "tippy.js/dist/tippy.css"; // Optional: tippy CSS for styling tooltips
import ReactDOM from "react-dom";
import MentionSuggestionList from "./MentionSuggestionList";
import useAppStore from "../../Store/Store";
import { useHistory, useLocation } from "react-router-dom"; // Import useHistory and useLocation
// import AutocompleteExtension from "./AutoCompleteExtension.jsx";
import { AutocompleteExtension } from "./AutoCompleteExtensionChatGPT2.jsx";

const MyEditorComponent = ({
  searchText,
  setSearchText,
  focusedPlaceholder,
  placeholders,
  isFocused,
  handleSearchButtonClick,
}) => {
  const editorRef = useRef(null);
  const mentionRef = useRef(null);

  const { tag, setTag, mounted, setMounted } = useAppStore();
  const history = useHistory(); // Initialize useHistory

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  // Function to handle the change in content
  const handleProcedureContentChange = (content) => {
    const regex = /{.([^}]*).}/g;
    const formattedContent = content.replace(
      regex,
      "<span style='color: #BD4533;'><i>$1</i></span>"
    );

    setSearchText(formattedContent);
  };

  // Rotate placeholders when editor is not focused
  useEffect(() => {
    if (!isEditorFocused) {
      const intervalId = setInterval(() => {
        setPlaceholderIndex(
          (prevIndex) => (prevIndex + 1) % placeholders.length
        );
      }, 2000); // Rotate every 5 seconds

      return () => clearInterval(intervalId);
    }
  }, [isEditorFocused, placeholders]);

  // Initialize the editor with extensions and content
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        keyboardShortcuts: {
          Enter: false, // Disable default "Enter" behavior
        },
      }),
      // AutocompleteExtension.configure({ char: "@" }),
      // AutocompleteExtension,
      Placeholder.configure({
        placeholder: placeholders[placeholderIndex],
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        renderHTML({ options, node }) {
          // This function allows you to customize the label of the mention
          return `${node.attrs.label}`; // Use the label without the "@" sign
        },
      }),
    ],
    content: searchText || "",
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      setSearchText(content);
      localStorage.setItem("searchText", content);
      handleProcedureContentChange(content);
    },
    onFocus: () => setIsEditorFocused(true),
    onBlur: () => setIsEditorFocused(false),
    editorProps: {
      handleKeyDown: (() => {
        let enterPressTimeout = null;
        let enterPressCount = 0;

        return (view, event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent default behavior

            enterPressCount++;

            if (enterPressCount === 1) {
              // Trigger the search function on the first Enter
              handleSearchButtonClick();

              // Reset after a short delay
              enterPressTimeout = setTimeout(() => {
                enterPressCount = 0;
              }, 300); // Adjust delay for double Enter (300ms recommended)
            }

            if (enterPressCount === 2) {
              // Allow line break on double Enter
              view.dispatch(view.state.tr.insertText("\n"));
              enterPressCount = 0; // Reset counter
              clearTimeout(enterPressTimeout);
            }

            return true; // Indicate the event was handled
          }

          return false; // Let other keys behave normally
        };
      })(),
    },
  });

  // Update editor content when searchText changes
  useEffect(() => {
    if (editor && searchText !== editor.getHTML()) {
      editor.commands.setContent(searchText);
    }
  }, [searchText, editor]);

  // Update placeholder configuration when placeholderIndex changes
  useEffect(() => {
    if (editor) {
      const placeholderExtension = editor.extensionManager.extensions.find(
        (ext) => ext.name === "placeholder"
      );
      placeholderExtension.options.placeholder = placeholders[placeholderIndex];
      editor.view.dispatch(editor.state.tr.setMeta("placeholder", {})); // Force update to refresh placeholder
    }
  }, [placeholderIndex, editor]);

  // Handle inserting a mention when tag changes
  useEffect(() => {
    if (editor && tag !== "") {
      handleInsertMention();
    }
  }, [tag, editor]);

  // Function to insert a mention into the editor
  const handleInsertMention = () => {
    if (!editor) return;

    const mentionNode = {
      type: "mention",
      attrs: { id: 1, label: tag }, // Example of hard-coded mention
    };

    editor
      .chain()
      .focus()
      .insertContent(" ")
      .insertContent(mentionNode)
      .insertContent(" ")
      .run();
    setSearchText(editor.getHTML()); // Update searchText with the content in the editor
    // console.log(searchText, "updatedfromtiptpa", mentionNode.attrs.label);
    setMounted(true);
  };

  // Trigger search when mounted state changes
  useEffect(() => {
    if (mounted) {
      // handleSearchButtonClick();
      console.log(mounted, "mounted");
      setMounted(false);
    }
  }, [mounted]);

  // Focus editor and handle key events, as well as allow enter button to trigger search
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Handle "Enter" key to trigger the search and prevent line breaks
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault(); // Prevent the default behavior (new line)
        handleSearchButtonClick(); // Trigger the search function
      }

      // Handle "Ctrl+A" or "Command+A" for select all
      if ((event.ctrlKey || event.metaKey) && event.key === "a") {
        event.preventDefault(); // Prevent the default behavior
        editor.commands.selectAll(); // Select all content in the editor
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editor, handleSearchButtonClick]);

  // Render the editor component
  return (
    <div className="editor-wrapper">
      <button
        className="menubar__button"
        onClick={handleInsertMention}
        style={{ display: "none" }}
      >
        <span>Insert Mention</span>
      </button>
      <EditorContent className="h-full" editor={editor} />
    </div>
  );
};

export default MyEditorComponent;
