import React, { useState } from "react";

const Popup = ({ eventName, onConfirm, onCancel }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleConfirm = () => {
    onConfirm();
    setIsVisible(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <p className="mb-4 text-lg">
          Are you sure you want to delete{" "}
          <span className="text-red-500">{eventName}</span>?
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-3xl hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Yes
          </button>
          <button
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-3xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
