// Dropdown.jsx
import React from "react";
import { Button } from "../../ui/button.jsx";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../ui/DropdownMenu.jsx";

const Dropdown = ({ label, options, selectedValue, onSelect }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button
        size="dropdown"
        variant="outline"
        className="rounded-full text-red-300 whitespace-nowrap bg-grey-400 border-red-300"
      >
        <span>{label}</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="w-48 bg-grey-500 absolute z-50 rounded-lg shadow-lg">
      {options.map((option) => (
        <DropdownMenuItem
          key={option.value}
          onClick={() => onSelect(option.value)}
          className="rounded-lg"
        >
          {option.label}
        </DropdownMenuItem>
      ))}
      <DropdownMenuSeparator />
    </DropdownMenuContent>
  </DropdownMenu>
);

export default Dropdown;
