// GoogleSignIn.js
import React from "react";
import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Global.js";
import { fetchAndSendSavedProductsToApi } from "../../Utils/ProductUtil.jsx";
import useAuthStore from "../../Store/AuthStore.jsx";

const GoogleSignIn = () => {
  const clientId =
    "1075592844578-e6mpdvc4ipm8omdkippd08bavdet3nv6.apps.googleusercontent.com";
  const history = useHistory();
  const { login } = useAuthStore();

  const onSuccess = (response) => {
    // console.log("Login Success: currentUser:", response.profileObj);
    // console.log("Access Token:", response.accessToken);
    // console.log(response);

    axios
      .post(
        `${BASE_URL}/api/user/google`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.tokenId}`,
          },
        }
      )
      .then(async (response) => {
        console.log("Server Response:", response.data);
        if (response.status === 200) {
          const token = response.data.token;
          const email = response.data.email;
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("email", email);
          window.localStorage.setItem("loggedin", JSON.stringify(true));
          fetchAndSendSavedProductsToApi();
          login(email, token.token);

          history.push("/search");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFailure = (response) => {
    console.log("Login failed: res:", response);
  };
  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="w-full flex items-center justify-center px-4 py-2 border border-red-300 text-neutral-green-800 hover:bg-grey-600"
            >
            <FcGoogle className="mr-2 h-5 w-5" />
            Continue with Google
          </button>
        )}
        buttonText="Login with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

export default GoogleSignIn;
