import { React, useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "./genderpage.css";
// import "./Home.scss"
import logo from "../Assets/Images/GiftsLogo.png";
import NavBar from "../components/NavBar/NavBar2.jsx";
import Body from "../components/HomePage/Body.jsx";
import Footer from "../components/Footer/Footer.jsx";
import { fetchIPAndGeolocation } from "../Services/IPGeoHandler.jsx";
import axios from "axios";

const HomePage = () => {
  return (
    <div>
      <NavBar />
      <fetchIPAndGeolocation />
      <Body />
      <Footer />
    </div>
  );
};

export default HomePage;
