import { useState } from "react";
import React, { forwardRef } from "react";
import { BASE_URL } from "../../Global.js";

const FinalSection = React.forwardRef((props, ref) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [details, setDetails] = useState("");
  const [formError, setFormError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !companyEmail || !companyUrl) {
      setFormError("Please fill the form first");
    } else {
      setFormError("");
      setIsButtonDisabled(true);

      try {
        const formData = {
          firstName,
          lastName,
          companyEmail,
          companyUrl,
          details,
        };

        const response = await fetch(`${BASE_URL}/api/partner/partnerform`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          // Reset form fields
          setFirstName("");
          setLastName("");
          setCompanyEmail("");
          setCompanyUrl("");
          setDetails("");
          setIsSubmitted(true);
          setIsButtonDisabled(false);

          // Hide the message and enable button after 5 seconds
          setTimeout(() => {
            setIsSubmitted(false);
          }, 1500);
        } else {
          throw new Error("Failed to submit form data");
        }
      } catch (error) {
        setFormError("Failed to submit form data");
        setIsSubmitted(false);
        setIsButtonDisabled(false);
      }
    }
  };

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-red-400 rounded-t-[20px] ">
      <div className="container mx-auto px-4 md:px-6 flex flex-col lg:flex-row gap-8">
        {/* Left Side: Title and Description */}
        <div className="lg:w-1/2">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-grey-500">
            Join Ciqler's Ecosystem
          </h2>
          <p className="text-lg text-grey-500 mt-4">
            Transform your products to empathetic products and reach new
            heights.
          </p>
        </div>

        {/* Right Side: Form */}
        <div
          ref={ref}
          className="bg-white rounded-lg shadow-md p-6 space-y-6 max-w-xl w-full"
        >
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="firstName"
                className="text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                placeholder="John"
                required
                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="lastName"
                className="text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                placeholder="Doe"
                required
                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="companyEmail"
                className="text-sm font-medium text-gray-700"
              >
                Company Email
              </label>
              <input
                id="companyEmail"
                type="email"
                placeholder="example@company.com"
                required
                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="companyUrl"
                className="text-sm font-medium text-gray-700"
              >
                Company URL
              </label>
              <input
                id="companyUrl"
                type="url"
                placeholder="https://www.company.com"
                required
                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                value={companyUrl}
                onChange={(e) => setCompanyUrl(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="details"
                className="text-sm font-medium text-gray-700"
              >
                Additional Details (optional)
              </label>
              <textarea
                id="details"
                placeholder="Enter details here..."
                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>

            <button
              type="submit"
              disabled={isButtonDisabled}
              className={`w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${
                isButtonDisabled
                  ? "opacity-50 cursor-not-allowed"
                  : "bg-gray-400"
              }`}
            >
              {isButtonDisabled ? "Submitting..." : "Submit"}
            </button>
            {formError && <p className="text-red-500 text-sm">{formError}</p>}
            {isSubmitted && (
              <p className="text-green-500 text-sm">
                Thank you for registering, we will get in contact with you soon.
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
});

export default FinalSection;
