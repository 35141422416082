import React from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
import "./autostyles.css"; // Ensure correct path

class AutoComplete extends React.Component {
  state = {
    value: "",
    suggestions: [],
  };

  // This method sets up debouncing for the onSuggestionsFetchRequested method
  componentDidMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    );
  }

  // This method defines the style for each suggestion based on its _query property
  renderSuggestion = (suggestion) => {
    let suggestionStyle;
    switch (suggestion._query) {
      case "searches_completion":
        suggestionStyle = { backgroundColor: "#d9e9ff" };
        break;
      case "searches_as_you_type":
        suggestionStyle = { backgroundColor: "#d3f9d8" };
        break;
      case "match":
        suggestionStyle = { backgroundColor: "#ffddc1" };
        break;
      case "match_phrase_prefix":
        suggestionStyle = { backgroundColor: "#fddfdf" };
        break;
      default:
        suggestionStyle = {};
    }

    return (
      <div className="result" style={suggestionStyle}>
        <div>{suggestion.text_entry}</div>
      </div>
    );
  };

  // This method handles the change event of the input field and triggers suggestions fetch
  onChange = (event, { newValue }) => {
    this.setState({ value: newValue }, () => {
      this.onSuggestionsFetchRequested({ value: newValue });
    });
  };

  // This method is called when suggestions need to be fetched based on the input value
  onSuggestionsFetchRequested = ({ value }) => {
    this.fetchCombinedSuggestions(value);
  };

  // This method constructs a query and sends a POST request to fetch suggestions
  fetchCombinedSuggestions = (value) => {
    const query = {
      min_score: 0.1,
      query: {
        function_score: {
          query: {
            bool: {
              must: [],
              should: [
                {
                  multi_match: {
                    query: value,
                    type: "bool_prefix",
                    fields: [
                      "text_entry.as_you_type",
                      "text_entry.as_you_type._2gram",
                      "text_entry.as_you_type._3gram",
                    ],
                  },
                },
                {
                  match: {
                    text_entry: {
                      query: value,
                      analyzer: "standard",
                    },
                  },
                },
                {
                  match_phrase_prefix: {
                    text_entry: {
                      query: value,
                      slop: 5,
                    },
                  },
                },
                {
                  function_score: {
                    query: {
                      match_all: {},
                    },
                    functions: [
                      {
                        filter: {
                          match: {
                            text_entry_completion: {
                              query: value,
                            },
                          },
                        },
                        weight: 2,
                      },
                    ],
                    boost_mode: "multiply",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          boost_mode: "sum",
        },
      },
      size: 5,
    };

    axios
      .post("https://auto.ciqler.com:9200/searchesv2/_search", query, {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: "admin",
          password: "Ciqler@24auto",
        },
      })
      .then((res) => {
        const suggestions = res.data.hits.hits.map((hit) => ({
          ...hit._source,
          _query: hit._type, // Assuming the type is stored here
        }));
        this.setState({ suggestions });
      })
      .catch((error) => {
        console.error("Error fetching combined suggestions:", error);
      });
  };

  // This method clears the suggestions when needed
  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  // This method renders the AutoComplete component
  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Type something...",
      value,
      onChange: this.onChange,
    };

    return (
      <div className="App">
        <h1>AutoComplete Demo</h1>
        <Autosuggest
          suggestions={suggestions || []} // Ensure suggestions is always an array
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={(suggestion) => suggestion.text_entry}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

export default AutoComplete;


// const res = await axios.post(
//   "https://auto.ciqler.com:9200/searchesv2/_search",
//   queryObj,
//   {
//     headers: { "Content-Type": "application/json" },
//     auth: { username: "admin", password: "Ciqler@24auto" },
//   }
// );