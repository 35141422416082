import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TiVendorMicrosoft } from "react-icons/ti";
import { login } from "../../Services/api.jsx";
import { validateEmail, validatePassword } from "../../Utils/Validation.jsx";
import GoogleSignIn from "./GoogleSignIn";
import FacebookSignin from "./FacebookSignin.jsx";
import useAuthStore from "../../Store/AuthStore.jsx";
import { fetchAndSendSavedProductsToApi } from "../../Utils/ProductUtil.jsx";

const SignIn = ({ setIsSignIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const login = useAuthStore((state) => state.login);
  const { login: setLogin } = useAuthStore();

  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    const emailValidationError = validateEmail(email);
    // const passwordValidationError = validatePassword(password);

    if (emailValidationError) {
      setEmailError(emailValidationError);
      // setPasswordError(passwordValidationError);
      setErrorMessage("Please fix the errors above.");
    } else {
      setEmailError("");
      setPasswordError("");
      setErrorMessage("");
      try {
        const response = await login(email, password);
        console.log(response);
        if (response.status === 403) {
          setErrorMessage("Invalid email or password. Please try again.");
        } else {
          const token = await response.json();
          window.localStorage.setItem("token", token.token);
          window.localStorage.setItem("email", email);
          localStorage.setItem("loggedin", JSON.stringify(true));
          setLogin(email, token.token);
          fetchAndSendSavedProductsToApi();
          history.push("/search");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="w-full max-w-md space-y-8">
      <div>
        <h2 className="mt-6 text-center text-3xl font-bold text-neutral-green-800">
          Welcome to <span className="text-red-500">Ciqler</span>
        </h2>
        <p className="mt-2 text-center text-sm text-neutral-green-600">
          Sign in to your account or create a new one
        </p>
      </div>
      <div className="space-y-4">
        {/* <button className="w-full flex items-center justify-center px-4 py-2 border border-red-300 text-neutral-green-800 hover:bg-grey-600">
          <FcGoogle className="mr-2 h-5 w-5" />
          Continue with Google
        </button> */}
        <GoogleSignIn />

        {/* <button className="w-full flex items-center justify-center px-4 py-2 border border-red-300 text-neutral-green-800 hover:bg-grey-600">
          <TiVendorMicrosoft className="mr-2 h-5 w-5" />
          Continue with Microsoft
        </button> */}
        {/* <FacebookSignin /> */}
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-neutral-green-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className=" bg-grey-100 px-2 text-neutral-green-600">
            Or continue with
          </span>
        </div>
      </div>
      <div className="space-y-6">
        <form onSubmit={handleLogin}>
          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="email"
                className=" text-sm font-medium text-neutral-green-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                className="w-full px-3 py-2 border border-neutral-green-300 rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <p className="text-red-500 text-sm">{emailError}</p>
              )}
            </div>
            <div className="space-y-2">
              <label
                htmlFor="password"
                className=" text-sm font-medium text-neutral-green-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="Password"
                required
                className="w-full px-3 py-2 border border-neutral-green-300 rounded-md"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <p className="text-red-500 text-sm">{passwordError}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row-reverse items-center justify-between pt-6">
            {/* <div className="flex items-center">
              <input
                id="remember"
                type="checkbox"
                className="h-4 w-4 text-red-600 border-neutral-green-300 rounded"
              />
              <label
                htmlFor="remember"
                className="ml-2 text-sm text-neutral-green-900"
              >
                Remember me
              </label>
            </div> */}
            <div className="text-sm">
              <a
                href="/forget-password"
                className="font-medium text-red-600 hover:underline"
              >
                Forgot your password?
              </a>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
          >
            Sign in
          </button>
          {errorMessage && (
            <p className="text-red-500 text-sm pt-2">{errorMessage}</p>
          )}
        </form>
      </div>
      <div className="flex items-center justify-center">
        <div className="text-sm">
          Don't have an account?{" "}
          <a
            href="#"
            className="font-medium text-red-600 hover:underline"
            onClick={(e) => {
              e.preventDefault();
              setIsSignIn(false);
            }}
          >
            Sign up
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
