import { Zap, Sparkles, Rocket, Users } from "lucide-react";

const iconMap = {
  Zap: <Zap className="h-6 w-6" />,
  Sparkles: <Sparkles className="h-6 w-6" />,
  Rocket: <Rocket className="h-6 w-6" />,
  Users: <Users className="h-6 w-6" />,
};

export default function EmpathyProcessSection({
  pipelineSteps,
  currentStep,
  sectionRef,
}) {
  return (
    <section
      ref={sectionRef}
      className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-r from-grey-100 to-grey-100"
    >
      <div className="container px-4 md:px-6">
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-orange-600">
          Empathy Cultivation Process
        </h2>
        <p className="mx-auto max-w-[700px] text-gray-700 text-center md:text-xl dark:text-neutral-green-400 mb-8">
          Revolutionize your brand with human-centric experiences that cultivate
          deep emotional connections.
        </p>
        <div className="flex justify-center">
          {pipelineSteps.map((step, index) => (
            <div
              key={index}
              className={`relative p-8 transition-all duration-500 ${
                currentStep === index
                  ? "opacity-100 text-red-500"
                  : "opacity-50"
              }`}
            >
              <div className="w-16 h-16 flex items-center justify-center rounded-full border border-gray-300 mb-4">
                {iconMap[step.icon]}
              </div>
              <h3 className="text-lg font-semibold text-center">
                {step.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
