import React, { useState, useRef, useEffect } from "react";

// Define a functional component named DropdownMenu that takes children as a prop
export const DropdownMenu = ({ children }) => {
  // Initialize state variables isOpen and setIsOpen using the useState hook
  const [isOpen, setIsOpen] = useState(false);
  // Create a reference to the dropdown element using the useRef hook
  const dropdownRef = useRef(null);

  // Implement an effect hook to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click is outside the dropdown element
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener for mousedown to detect clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Render the DropdownMenu component with its children
  return (
    <div className="relative" ref={dropdownRef}>
      {React.Children.map(children, (child) => {
        // Check if the child is of type DropdownMenuTrigger and update its onClick event
        if (child.type === DropdownMenuTrigger) {
          return React.cloneElement(child, {
            onClick: () => setIsOpen(!isOpen),
          });
        }
        // Check if the child is of type DropdownMenuContent and pass the isOpen state
        if (child.type === DropdownMenuContent) {
          return React.cloneElement(child, {
            isOpen,
          });
        }
        // Return the child as is if it's not a special type
        return child;
      })}
    </div>
  );
};

// Define a functional component named DropdownMenuTrigger that handles onClick events
export const DropdownMenuTrigger = ({ children, onClick }) => {
  return <div onClick={onClick}>{children}</div>;
};

// Define a functional component named DropdownMenuContent that displays content based on isOpen state
export const DropdownMenuContent = ({
  children,
  isOpen,
  align = "start",
  ...props
}) => {
  // Render the content only if isOpen is true
  if (!isOpen) return null;

  // Define alignment classes for positioning the content
  const alignmentClasses = {
    start: "left-0",
    end: "right-0",
  };

  // Render the content with specified alignment and other props
  return (
    <div
      className={`absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${alignmentClasses[align]}`}
      {...props}
    >
      <div className="py-1">{children}</div>
    </div>
  );
};

// Define a functional component named DropdownMenuItem that represents an item in the dropdown menu
export const DropdownMenuItem = ({
  children,
  onSelect,
  className = "",
  ...props
}) => {
  return (
    <button
      onClick={onSelect}
      className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-red-300 hover:text-neutral-100  ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

// Define a functional component named DropdownMenuSeparator that renders a separator in the dropdown menu
export const DropdownMenuSeparator = () => {
  return <div className="border-t border-gray-100" />;
};
