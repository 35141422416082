// components/FeaturedEventsComponent.jsx
"use client";

import { useRef, useEffect, useState } from "react";
import { ScrollArea } from "../../../ui/scrollArea";
import EventCard from "./EventCard";
import ScrollButtons from "./ScrollButtons";
import useEventStore from "../../../../Store/EventStore.jsx";

export default function FeaturedEventsComponent({ events }) {
  const [addedEvents, setAddedEvents] = useState([]);
  const scrollRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const { handleAddEvent: addEvents } = useEventStore();

  // Update the scroll visibility status when scrolling
  const handleScroll = () => {
    if (scrollRef.current) {
      setShowLeftScroll(scrollRef.current.scrollLeft > 0);
      setShowRightScroll(
        scrollRef.current.scrollLeft <
          scrollRef.current.scrollWidth - scrollRef.current.clientWidth
      );
    }
  };

  // Function to scroll left or right based on the button click
  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount =
        direction === "left"
          ? -scrollRef.current.clientWidth / 2
          : scrollRef.current.clientWidth / 2; // Scroll by half the width of the visible area
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  // Function to add an event using the EventStore
  const handleAddEvent = (event) => {
    addEvents(event);
  };

  // Attach the scroll listener to update button visibility
  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      return () => currentRef.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <div className="min-w-sm max-w-6xl mx-auto space-y-12">
      <div className="relative">
        <ScrollArea className="w-full rounded-full bg-grey-500  backdrop-blur-sm">
          <div ref={scrollRef} className="flex w-max space-x-4 p-6">
            {events.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                onAdd={handleAddEvent}
                isAdded={addedEvents.some((e) => e.id === event.id)}
              />
            ))}
          </div>
        </ScrollArea>
        <ScrollButtons
          showLeftScroll={showLeftScroll}
          showRightScroll={showRightScroll}
          onScroll={scroll}
        />
      </div>
    </div>
  );
}